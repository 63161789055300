const Pop = (): JSX.Element => (
  <svg
    width="15"
    height="12"
    viewBox="0 0 68 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34 94C44 94 64 88.55 64 62.24C64 35.92 34 4 34 4C34 4 4 35.92 4 62.24C4 88.55 24 94 34 94Z"
      stroke="currentColor"
      strokeWidth="7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Pop
