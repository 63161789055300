const aTest = (): JSX.Element => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 113 108"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 73.8466L9.07552 77.9035C11.6806 79.9873 14.9624 81.0378 18.2933 80.8542C21.6242 80.6706 24.7706 79.2657 27.1309 76.9082C29.5466 74.4916 32.7854 73.076 36.1998 72.9443C39.6142 72.8127 42.9525 73.9747 45.5471 76.1981L46.5948 77.0945C52.2291 81.9256 60.5408 81.9256 66.1751 77.0945L67.2228 76.1981C69.8167 73.974 73.1546 72.8109 76.569 72.9415C79.9834 73.0721 83.2226 74.4866 85.6391 76.9024C87.9986 79.2608 91.1446 80.6667 94.4755 80.8514C97.8065 81.0361 101.089 79.9866 103.694 77.9035L108.77 73.8466M105.86 97.1288L103.287 99.7015C98.2813 104.707 90.1616 104.707 85.1501 99.7015C82.8656 97.4203 79.8045 96.0848 76.5783 95.9618C73.3522 95.8388 70.1983 96.9373 67.7467 99.038L66.1751 100.377C60.5408 105.208 52.2291 105.208 46.5948 100.377L45.0233 99.038C42.5716 96.9373 39.4178 95.8388 36.1916 95.9618C32.9654 96.0848 29.9044 97.4203 27.6198 99.7015C22.6141 104.707 14.4945 104.707 9.48296 99.7015L6.91028 97.1288M73.8466 37.8989C73.8466 53.2128 62.2055 56.385 56.385 56.385C50.5644 56.385 38.9233 53.2128 38.9233 37.8989C38.9233 22.5792 56.385 4 56.385 4C56.385 4 73.8466 22.5792 73.8466 37.8989Z"
      strokeWidth="7"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
    />
  </svg>
)

export default aTest
